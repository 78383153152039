<template>
  <section class="content">
    <breadcrumb></breadcrumb>
    <div class="account-element flex">
      <div class="left-content">
        <a-tree
          :tree-data="treeList"
          :selectedKeys.sync="selectedKeys"
          :expanded-keys="['all']"
          @select="onSelect"
        />
      </div>
      <div class="table-content">
        <div class="table-header">
          <Pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="total"
            @paginate="paginate"
          />
        </div>
        <div class="table-body">
          <a-table
            size="small"
            :columns="columns"
            :data-source="tableList"
            :loading="loading"
            :pagination="false"
            rowKey="code"
          >
            <template slot="code" slot-scope="text, record">
              <span class="text-link" @click="viewDetail(record)">{{ text }}</span>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <a-modal
      :title="$t('详情')"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      :width="800"
    >
      <a-row :gutter="16">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-col :span="12">
            <a-form-model-item :label="$t('编码')" prop="code">
              <a-input v-model="form.code" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('名称')" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('会计要素表')">
              <a-input v-model="form.root_name" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('余额方向')" prop="balance_direction">
              <a-radio-group v-model="form.balance_direction">
                <a-radio :value="key" v-for="(item, key) in balance_direction_mapping" :key="key">
                  {{ item }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('上级会计要素')">
              <a-input v-model="form.parent_name" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('备注')">
              <a-input v-model="form.remark" />
            </a-form-model-item>
          </a-col>
        </a-form-model>
      </a-row>
    </a-modal>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import deepClone from '@/common/deepClone'
import Pagination from '@/component/pagination'
export default {
  name: 'accountElement',
  components: { Pagination },
  mounted() {
    this.getTreeList()
    this.getTableList()
  },
  data() {
    return {
      treeLoading: false,
      loading: false,
      treeList: [],
      tableList: [],
      rootCode: undefined,
      selectedKeys: ['all'],
      visible: false,
      form: {
        code: '',
        name: '',
        balance_direction: '',
      },
      rules: {
        code: [{ required: true, message: this.$t('请输入编码'), trigger: 'blur' }],
        name: [{ required: true, message: this.$t('请输入名称'), trigger: 'blur' }],
        balance_direction: [
          { required: true, message: this.$t('请选择余额方向'), trigger: 'change' },
        ],
      },
      pageNo: 1,
      pageSize: 20,
      total: 0,
    }
  },
  computed: {
    ...mapState(['balance_direction_mapping']),
    columns() {
      return [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: this.$t('编码'),
          dataIndex: 'code',
          scopedSlots: { customRender: 'code' },
        },
        {
          title: this.$t('名称'),
          dataIndex: 'name',
        },
        {
          title: this.$t('会计要素表'),
          dataIndex: 'root_name',
        },
        {
          title: this.$t('余额方向'),
          dataIndex: 'balance_direction',
          customRender: (text) => this.balance_direction_mapping[text],
        },
        {
          title: this.$t('上级会计要素'),
          dataIndex: 'parent_name',
        },
        {
          title: this.$t('系统预置'),
          dataIndex: 'is_preset',
          customRender: (text) => {
            return text ? this.$t('是') : this.$t('否')
          },
        },
        {
          title: this.$t('备注'),
          dataIndex: 'remark',
        },
      ]
    },
  },
  methods: {
    getTreeList() {
      this.treeLoading = true
      http({
        url: api.getAccountElementist,
        type: 'post',
        data: {
          level: 0,
        },
        success: (res) => {
          this.treeLoading = false
          this.treeList = [
            {
              title: this.$t('全部'),
              key: 'all',
              children: res.result.list.map((item) => ({
                title: item.name,
                key: item.code,
              })),
            },
          ]
        },
      })
    },
    getTableList() {
      this.loading = true
      http({
        url: api.getAccountElementist,
        type: 'post',
        data: { page_no: this.pageNo, page_size: this.pageSize, rootCode: this.rootCode },
        success: (res) => {
          this.loading = false
          this.tableList = res.result.list
          this.total = res.result.total
        },
      })
    },
    onSelect(selectedKeys) {
      if (selectedKeys.length) {
        if (selectedKeys[0] === 'all') {
          this.rootCode = undefined
        } else {
          this.rootCode = selectedKeys[0]
        }
        this.getTableList()
      }
    },
    viewDetail(record) {
      this.form = deepClone(record)
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      this.visible = false
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.getTableList()
    },
  },
}
</script>

<style scoped lang="scss">
.left-content {
  min-width: 150px;
  width: 250px;
  border-right: 1px solid #e8e8e8;
}
.table-content {
  flex: 1;
}
</style>
